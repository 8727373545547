import React, { useState } from "react";
import "./contactMap.css";
import FactoryIcon from "../../images/icon--map4.svg";
import FarmIcon from "../../images/icon--map5.svg";
import OfficeIcon from "../../images/icon--map6.svg";
import IconAll from "../../images/icon--map1.svg";
import FresheezOutlets from "../../images/icon--map7.svg";
import Other from "../../images/icon--map8.svg";
import GoogleMapReact from "google-map-react";
import { AiOutlinePhone } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";

// Marker component to render on the map
const Marker = ({ icon, name, showLabel }) => (
  <div className={`marker ${showLabel ? "hovered" : ""}`}>
    <img src={icon} alt={`${name} Icon`} className="marker-icon" />
    {showLabel && <span className="marker-label">{name}</span>}
  </div>
);

// Location data array
const locations = [
  {
    type: "office",
    location: "Head Office",
    name: "CIC Holding PLC",
    contactdetails: "011-2359359",
    address: "199 Kew Rd, Colombo 00200",
    lat: 6.92346,
    lng: 79.85277,
    icon: OfficeIcon,
  },
  {
    type: "factory",
    location: "Factory",
    name: "CIC Dairies(Pvt)Ltd",
    contactdetails: "066-2053508",
    address:
      "Rangiri Milk Processing Center, No 99, Colombo Road, Ibbankatuwa, Dambulla",
    lat: 7.84422,
    lng: 80.53296,
    icon: FactoryIcon,
  },
  {
    type: "factory",
    location: "",
    name: "CIC Feeds(Pvt)Ltd",
    contactdetails: "011-2229785",
    address: "252, Kurunduwatte Road Ekala Ja-ela, Udammita Road",
    lat: 7.11065,
    lng: 79.90043,
    icon: FactoryIcon,
  },
  {
    type: "office",
    location: "Sales Office | Stores",
    name: "CIC Agri Produce Export(Pvt)Ltd.",
    contactdetails: "011-2229785",
    address:
      "CIC Agri Produce Export(Pvt)Ltd, No 06, Wimalajothi Nahimi Mawatha, Ekala, Ja-Ela",
    lat: 7.09367,
    lng: 79.90445,
    icon: OfficeIcon,
  },
  {
    type: "office",
    location: "Sales Office | Stores",
    name: "CIC Dairies(Pvt)Ltd",
    contactdetails: "",
    address: "CIC Dairy, No 06, Wimalajothi Nahimi Mawatha, Ekala, Ja-Ela",
    lat: 7.09435,
    lng: 79.90394,
    icon: OfficeIcon,
  },
  {
    type: "farm",
    location: "Seed Paddy | Stores | Sales Office",
    name: "CIC Seeds(Pvt)Ltd",
    contactdetails: "077-9190462",
    address: "CIC Seed Private Limited, Udagama Rd, Mahawa",
    lat: 7.813754,
    lng: 80.2747497,
    icon: FarmIcon,
  },
  {
    type: "office",
    location: "Sales Office and Warehouse complex",
    name: "CIC Holdings",
    contactdetails: "",
    address:
      "CIC Holdings-Warehouse Complex, First Ln, Ja-Ela-Google map Address",
    lat: 7.09418,
    lng: 79.90394,
    icon: OfficeIcon,
  },
  {
    type: "office",
    location: "",
    name: "CIC Agri Produce Export(Pvt)Ltd.",
    contactdetails: "077-3060755 | 037-2275419",
    address: "CIC Agri Produce Export Private Limited, Udagama Rd, Mahawa",
    lat: 7.813754,
    lng: 80.2747497,
    icon: OfficeIcon,
  },
  {
    type: "factory",
    location: "",
    name: "CIC Agri Businesses(Pvt)Ltd",
    contactdetails: "011-4813010",
    address: "CIC Agri Businesses(Pvt)Ltd, 81,13 New Nuge Rd, Peliyagoda",
    lat: 6.96199,
    lng: 79.88931,
    icon: FactoryIcon,
  },
  {
    type: "farm",
    location: "Seed Paddy | Seed Veg",
    name: "CIC Seeds(Pvt)Ltd",
    contactdetails: "027-2246329",
    address: "CIC Seeds(Pvt)Ltd,CIC Seed Farm,Higurakgoda",
    lat: 8.04717,
    lng: 80.95178,
    icon: FarmIcon,
  },
  {
    type: "factory",
    location: "Factory | Stores",
    name: "CIC Dairies(Pvt)Ltd",
    contactdetails: "077-9250751",
    address: "CIC Seeds(Pvt)Ltd, CIC seed farm,CIC Dairy (Pvt)Ltd, Higurakgoda",
    lat: 8.0471,
    lng: 80.95183,
    icon: FactoryIcon,
  },
  {
    type: "office",
    location: "Quality Banana packging center | Sales Office",
    name: "CIC Agri Produce Marketing(Pvt)Ltd",
    contactdetails: "027-2246329",
    address:
      "CIC Seeds(Pvt)Ltd, CIC seed farm, CIC Agri Produce Marketing(pvt)Ltd, Higurakgoda",
    lat: 8.04713,
    lng: 80.95185,
    icon: OfficeIcon,
  },
  {
    type: "farm",
    location: "Seed Farm",
    name: "CIC Seeds(Pvt)Ltd",
    contactdetails: "076-3059742",
    address: "CIC Seeds(Pvt)Ltd, Malwanegama, Thalawa",
    lat: 8.1977493,
    lng: 80.3289843,
    icon: FarmIcon,
  },
  {
    type: "farm",
    location: "Stores",
    name: "CIC Agri Produce Export(Pvt)Ltd",
    contactdetails: "076-0891581",
    address: "CIC Agri Produce Export(Pvt)Ltd, Malwanegama, Thalawa",
    lat: 8.1977493,
    lng: 80.3289843,
    icon: FarmIcon,
  },
  {
    type: "farm",
    location: "Stores",
    name: "CIC Grain (PVT)Ltd",
    contactdetails: "076-0891581",
    address: "CIC Grain PVT(Ltd), Malwanegama, Thalawa",
    lat: 8.1977493,
    lng: 80.3289843,
    icon: FarmIcon,
  },
  {
    type: "office",
    location: "Office",
    name: "Unipower(Pvt)Ltd",
    contactdetails: "011-2451551",
    address:
      "Uni Power(Pvt)Ltd, No 75,IFI Tower 3, 8th Floor, Piyadasa Sirisena Mawatha, Colombo 10.",
    lat: 6.92441,
    lng: 79.86708,
    icon: OfficeIcon,
  },
  {
    type: "office",
    location: "Office",
    name: "Cropguard(Pvt)",
    contactdetails: "011-7559900",
    address:
      "CIC Crop Guard (Pvt)Ltd, No 75. IFI Tower, 8th Floor, Piyadasa Sirisena Mawatha, Colombo 10",
    lat: 6.92441,
    lng: 79.86708,
    icon: OfficeIcon,
  },
  {
    type: "farm",
    location: "Seed Farm | Sales Center",
    name: "CIC Seeds(Pvt)Ltd",
    contactdetails: "066-2285446",
    address: "CIC, Seed farm Pelwhera, Dambulla",
    lat: 7.89965,
    lng: 80.66775,
    icon: FarmIcon,
  },
  {
    type: "farm",
    location: "Quality Banana Packaging Center",
    name: "CIC Agri Produce  Marketing(Pvt)Ltd",
    contactdetails: "066-2285446",
    address: "CIC Agri Produce Marketing (Pvt)Ltd, Pelwhera, Dambulla",
    lat: 7.89974,
    lng: 80.66774,
    icon: FarmIcon,
  },
  {
    type: "factory",
    location: "RPC Factory",
    name: "CIC Holdings PLC",
    contactdetails: "011-2173281 | 011-2173396 | 011-2173399",
    address: "No 268/A Lenagala State, Panagoda, Homagama",
    lat: 6.8702,
    lng: 80.04032,
    icon: FactoryIcon,
  },
  {
    type: "factory",
    location: "Panagoda Works",
    name: "CIC Holdings PLC",
    contactdetails: "011-2175503 | 011-2175504",
    address: "No 268/A/1 Lenagala State, Panagoda, Homagama",
    lat: 6.87022,
    lng: 80.04028,
    icon: FactoryIcon,
  },
  {
    type: "office",
    location: "Sales Office and Warehouse Complex",
    name: "CIC AgriBusiness(Pvt)Ltd",
    contactdetails: "077 3976200",
    address:
      "CIC Agri Business(Pvt)Ltd, Tharupathi Building, Near Pinaduwa Highway interchain, Walahaduwa, Galle",
    lat: 6.0528,
    lng: 80.25873,
    icon: OfficeIcon,
  },
  {
    type: "office",
    location: "Fertilize Factory | Sales Office",
    name: "CIC Agri Businesses",
    contactdetails: "037-2224210 | 077-3863088",
    address: "CIC Agri Business(Pvt)Ltd, Mehiella,Kurunegala",
    lat: 7.47767,
    lng: 80.39804,
    icon: OfficeIcon,
  },
  {
    type: "office",
    location: "Sales Office",
    name: "CIC Agri Businesses | CIC Seeds",
    contactdetails: "081-2203433 | 077 340 3149",
    address:
      "CIC Agri Business(Pvt)Ltd, 4th floor,Siriwardhana Building,752/4/1 Peradeniya Rd,Kandy",
    lat: 7.27395,
    lng: 80.60896,
    icon: OfficeIcon,
  },
  {
    type: "office",
    location: "Sales Office",
    name: "CIC Agri Businesses",
    contactdetails: "045-2224547 | 076 565 2564",
    address: "642/1 Colombo Rd,Newtown ,Rathnapura",
    lat: 6.7056371,
    lng: 80.3852935,
    icon: OfficeIcon,
  },
  {
    type: "office",
    location: "Seed Stores",
    name: "CIC Seeds(Pvt)ltd",
    contactdetails: "077-9867877",
    address: "CIC Seeds(Pvt)Ltd,Panamura Rd, Udugama,Embilipitiya",
    lat: 6.3375,
    lng: 80.84242,
    icon: OfficeIcon,
  },
  {
    type: "office",
    location: "Seed Stores | Sales Office",
    name: "CIC Seeds(Pvt)Ltd | CIC Agri Businesses(Pvt)Ltd",
    contactdetails: "057-2244185 | 077-386 8595",
    address: "CIC Agri Business (Pvt)Ltd,Nuwaraeliya Rd,Nugathalawa, Welimada",
    lat: 6.90247,
    lng: 80.89458,
    icon: OfficeIcon,
  },
  {
    type: "fresheez",
    location: "Fresheez Outlet",
    name: "CIC Seeds(Pvt)ltd",
    contactdetails: "077-2852433",
    address: "No 76, Jawatta Rd, Colombo 5",
    lat: 6.89425,
    lng: 79.86683,
    icon: FresheezOutlets,
  },
  {
    type: "fresheez",
    location: "Fresheez Outlet",
    name: "CIC Seeds(Pvt)ltd",
    contactdetails: "077-1596025",
    address: "Nondescripts Cricket Club, No 29, Mitland Place, Colombo 7",
    lat: 6.90871,
    lng: 79.86659,
    icon: FresheezOutlets,
  },
  {
    type: "fresheez",
    location: "Fresheez Outlet",
    name: "CIC Seeds(Pvt)ltd",
    contactdetails: "077-3487482",
    address: "The Central, No114, Norrs Canel Rd, Colombo 10",
    lat: 6.92066,
    lng: 79.86602,
    icon: FresheezOutlets,
  },
  {
    type: "fresheez",
    location: "Fresheez Outlet",
    name: "CIC Seeds(Pvt)ltd",
    contactdetails: "077-1221594",
    address: "Canovin Archade, Colombo-Galle, Southern Expressway A-Welipenna",
    lat: 6.49093,
    lng: 80.07797,
    icon: FresheezOutlets,
  },
  {
    type: "fresheez",
    location: "Fresheez Outlet",
    name: "CIC Seeds(Pvt)ltd",
    contactdetails: "077-1241047",
    address: "Canovin Archade, Colombo-Galle, Southern Expressway B-Welipenna",
    lat: 6.47009,
    lng: 80.07647,
    icon: FresheezOutlets,
  },
  {
    type: "fresheez",
    location: "Fresheez Outlet",
    name: "CIC Seeds(Pvt)ltd",
    contactdetails: "076-8473869",
    address: "CIC Seed farm, Pelwhera, Dambulla",
    lat: 7.90037,
    lng: 80.66758,
    icon: FresheezOutlets,
  },
  {
    type: "other",
    location: "Cisco Packaging",
    name: "Cisco Speciality Packaging (Pvt) Ltd",
    contactdetails: "011-2700988",
    address: "No 359, Nagaha Mawatha, Pelenwatte, Pannipitiya",
    lat: 6.82131,
    lng: 79.94563,
    icon: Other,
  },
  {
    type: "other",
    location: "Diary Office",
    name: "CIC Dairies(Pvt)Ltd",
    contactdetails: "011-2229784",
    address: "No 06, Temple Rd, Ekala, Ja-Ela",
    lat: 7.09062,
    lng: 79.90403,
    icon: Other,
  },
  {
    type: "other",
    location: "Link Natural",
    name: "CICLink Natural(Pvt)Ltd",
    contactdetails: "011-5606060 | 011-2145502",
    address: "Link Natural Product (Pvt)Ltd, PO 2, Malinda, Kapugoda",
    lat: 6.94596,
    lng: 80.07679,
    icon: Other,
  },
  {
    type: "other",
    location: "Link Natural Shop",
    name: "CICLink Natural(Pvt)Ltd",
    contactdetails: "071-1950103",
    address:
      "Link Natural Product (Pvt)Ltd, People's Park Shopping Complex , Colombo 11",
    lat: 6.93917,
    lng: 79.85499,
    icon: Other,
  },
];

export default function ContactMap() {
  // Set default selected location type to 'all'
  const [selectedLocationType, setSelectedLocationType] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [hoveredLocation, setHoveredLocation] = React.useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 6.927079,
    lng: 79.861244,
  });
  const [mapZoom, setMapZoom] = useState(8); // Default zoom level

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Filter locations based on search term
  const filteredLocations = locations.filter((loc) => {
    // If there's a search term, include all locations that match the term
    if (searchTerm) {
      return loc.name.toLowerCase().includes(searchTerm);
    }
    // Otherwise, filter by selected location type
    return selectedLocationType === "all" || loc.type === selectedLocationType;
  });

  // Update the location type when an icon is clicked
  const handleLocationTypeChange = (type) => {
    setSelectedLocationType(type);
    setSearchTerm(""); // Reset the search term when changing location type
  };

  // Center the map on the hovered location
  const handleMouseEnter = (location) => {
    setHoveredLocation(`${location.lat}-${location.lng}`);
    setMapCenter({ lat: location.lat, lng: location.lng });
    setMapZoom(10); // Zoom in on the hovered location
  };

  const handleMouseLeave = () => {
    setHoveredLocation(null);
    setMapCenter({
      lat: filteredLocations[0]?.lat || 6.927079,
      lng: filteredLocations[0]?.lng || 79.861244,
    });
    setMapZoom(8); // Reset to default zoom level
  };

  return (
    <div className="contact-map-container">
      {/* Search and Filter Bar */}
      <div className="search-filter-bar">
        <input
          type="text"
          placeholder="Search location"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className="location-icons">
          <div
            className={`location-icon-item ${
              selectedLocationType === "all" ? "active" : ""
            }`}
            onClick={() => handleLocationTypeChange("all")}
          >
            <img className="icon-all" src={IconAll} alt="All Locations" />
            <span>All</span>
          </div>
          <div
            className={`location-icon-item ${
              selectedLocationType === "factory" ? "active" : ""
            }`}
            onClick={() => handleLocationTypeChange("factory")}
          >
            <img src={FactoryIcon} alt="Factory" />
            <span>Factory</span>
          </div>
          <div
            className={`location-icon-item ${
              selectedLocationType === "farm" ? "active" : ""
            }`}
            onClick={() => handleLocationTypeChange("farm")}
          >
            <img src={FarmIcon} alt="Farm" />
            <span>Farm</span>
          </div>
          <div
            className={`location-icon-item ${
              selectedLocationType === "office" ? "active" : ""
            }`}
            onClick={() => handleLocationTypeChange("office")}
          >
            <img src={OfficeIcon} alt="Office" />
            <span>Office</span>
          </div>
          <div
            className={`location-icon-item ${
              selectedLocationType === "fresheez" ? "active" : ""
            }`}
            onClick={() => handleLocationTypeChange("fresheez")}
          >
            <img src={FresheezOutlets} alt="Fresheez" />
            <span>Fresheez Outlets</span>
          </div>
          <div
            className={`location-icon-item ${
              selectedLocationType === "other" ? "active" : ""
            }`}
            onClick={() => handleLocationTypeChange("other")}
          >
            <img src={Other} alt="Other" />
            <span>Other</span>
          </div>
        </div>
      </div>

      <p className="total-location">
        Total Locations: {filteredLocations.length}
      </p>

      {/* Main Content: Locations List and Map */}
      <div className="content-wrapper">
        {/* Locations List */}
        <div className="locations-list">
          {filteredLocations.map((location, index) => (
            <div
              key={index}
              className="location-card"
              onMouseEnter={() => handleMouseEnter(location)} // Set hovered location and center the map
              onMouseLeave={handleMouseLeave} // Clear hovered location
            >
              <h4 className="location-title">{location.name}</h4>
              <span className="location-subtitle">{location.location}</span>
              <p>
                <div>
                  {location.contactdetails.split("|").map((number, index) => (
                    <a
                      key={index}
                      href={`tel:${number.trim()}`}
                      style={{ display: "block", margin: "5px 0" }}
                      className="contact-number-link"
                    >
                      <AiOutlinePhone className="location-phone-icon" />
                      {number.trim()}
                    </a>
                  ))}
                </div>
              </p>
              <p className="location-address">
                <IoLocationOutline className="location-address-icon" />
                <span>{location.address}</span>
              </p>
            </div>
          ))}
        </div>

        {/* Google Map */}
        <div className="map-container">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBo731CKuuWhNCOmffRhUFEvcxN4IswuNI",
            }}
            center={mapCenter} // Center based on hovered location
            zoom={mapZoom}
          >
            {filteredLocations.map((loc, index) => (
              <Marker
                key={index}
                lat={loc.lat}
                lng={loc.lng}
                icon={loc.icon}
                name={loc.name}
                showLabel={hoveredLocation === `${loc.lat}-${loc.lng}`} // Check if this location is hovered
              />
            ))}
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
}
