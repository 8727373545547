import React from "react";
import { FiMail } from "react-icons/fi";
import { PiBuildingOffice } from "react-icons/pi";
import { AiOutlinePhone } from "react-icons/ai";
import { LiaFaxSolid } from "react-icons/lia";
import "./ContactCard.css";

const HoverDevCards = () => {
  return (
    <div className="HoverCard-container">
      <div className="HoverCard-grid">
        <Card
          title="Office"
          subtitle={
            <>
              199, Kew Road, <br /> Colombo 2
            </>
          }
          href="https://maps.app.goo.gl/AL7n2YJdQ5xxz86D7"
          Icon={PiBuildingOffice}
        />
        <Card
          title="Phone"
          subtitle={["+94 112328421", "+94 112328426", "+94 112359359"]}
          href={["tel:+94112328421", "tel:+94112328426", "tel:+94112359359"]}
          Icon={AiOutlinePhone}
        />
        <Card
          title="Fax"
          subtitle="+94 112446922"
          href="fax:+94112446922"
          Icon={LiaFaxSolid}
        />
        <Card
          title="Email"
          subtitle={["cicho@cic.lk", "info@cic.lk"]}
          href={["mailto:cicho@cic.lk", "mailto:info@cic.lk"]}
          Icon={FiMail}
        />
      </div>
    </div>
  );
};

const Card = ({ title, subtitle, Icon, href }) => {
  const renderContent = () => {
    if (Array.isArray(subtitle) && Array.isArray(href)) {
      return subtitle.map((item, index) => (
        <a key={index} href={href[index]} className="HoverCard-subLink">
          {item}
        </a>
      ));
    }
    if (href !== "#") {
      return (
        <a href={href} className="HoverCard-subLink">
          {subtitle}
        </a>
      );
    }
    return <p className="HoverCard-subtitle">{subtitle}</p>;
  };

  return (
    <div className="HoverCard-card">
      <div className="HoverCard-gradient" />
      <Icon className="HoverCard-bgIcon" />
      <Icon className="HoverCard-icon" />
      <h3 className="HoverCard-title">{title}</h3>
      <div className="HoverCard-content">{renderContent()}</div>
    </div>
  );
};

export default HoverDevCards;
