import React from "react";
// import Header from "../components/header"
// import Footer from "../components/footer"
import ContactMainBanner from "../templates/ContactUs/contactMainBanner";
// import ContactModes from "../templates/ContactUs/contactModes";
import ContactGetTouch from "../templates/ContactUs/contactGetTouch";
import ContactMap from "../templates/ContactUs/contactMap";
import ContactCards from "../templates/ContactUs/ContactCards";

export default function ContactUs() {
  return (
    <div>
      {/* <Header /> */}
      <ContactMainBanner />
      {/* <ContactModes /> */}
      <ContactCards />
      <ContactGetTouch />
      <ContactMap />
      {/* <Footer /> */}
    </div>
  );
}
