import React from "react";
import "./contactMainBanner.css";
import ContactImage from "../../images/Contact-Us-Main.webp";
// import ContactVideo from "../../images/contactMainBanner1.mp4"

export default function ContactMainBanner() {
  return (
    <div>
      <div>
        <div style={{ position: "relative" }}>
          <img className="contact-home-image" alt="" src={ContactImage} />
          {/* <video 
            className="contact-home-image" 
            src={ContactVideo}
            autoPlay
            loop
            muted 
          /> */}
          <div className="contact-image-text-box">
            <div className="contact-image-background-text">
              <h1 className="contact-image-topic">Contact Us</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
